import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import Layout from '../layout/Layout'
import config from '../config/theme'
import SectionHeader from '../components/SectionHeader'
import SectionImageText from '../components/SectionImageText'
import ImageSamochodyCiezarowe from '../images/flota/samochody-ciezarowe.svg'
import ImageCiagniki from '../images/flota/ciagniki.svg'
import ImageSamochodyCiezarowePrzyczepy from '../images/flota/samochody-ciezarowe-przyczepy.svg'
import ImageSamochodyDostawcze from '../images/flota/samochody-dostawcze.svg'
import ImageCiagnikiSpecjalistyczne from '../images/flota/ciagniki-specjalistyczne.svg'
import ImageWywrotki from '../images/flota/wywrotki.svg'

export default function Home() {
  const { headerBgMobile, headerBg } = useStaticQuery(
    graphql`
      query {
        headerBgMobile: file(
          relativePath: { eq: "flota/flota-bg-mobile.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData
          }
        }
        headerBg: file(relativePath: { eq: "flota/flota-bg.jpg" }) {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    `
  )

  const headerImages = [
    { ...getImage(headerBgMobile) },
    {
      ...getImage(headerBg),
      media: `(min-width: ${config.base.breakpoints.lgUp})`,
    },
  ]

  return (
    <Layout
      metaTitle="Flota"
      metaDescription="Sprawdź jakimi pojazdami dostarczamy towary do celu. Aby zapewnić wysoki poziom świadczonych usług, zawsze dobieramy je indywidualnie, pod kątem Twoich potrzeb i specyfiki zlecenia."
      slug="/flota"
    >
      <SectionHeader
        smallTitle="Usługi"
        title="Flota"
        titleBorder="white"
        lead="Sprawdź jakimi pojazdami dostarczamy towary do&nbsp;celu. Aby&nbsp;zapewnić wysoki poziom świadczonych usług, zawsze dobieramy je indywidualnie, pod kątem Twoich potrzeb i&nbsp;specyfiki zlecenia."
        buttonType="bordered"
        scroll="#czytaj-wiecej"
        bgImage={headerImages}
      />
      <SectionImageText
        id="czytaj-wiecej"
        smallTitle="Flota"
        title="Samochody ciężarowe&nbsp;(solówki)"
        content={
          <>
            Solówkami określa się standardowe samochody ciężarowe pozbawione
            naczepy. Możliwość załadowania dużej zawartości ładunku przekłada
            się na wszechstronność ich wykorzystania – to najbardziej
            uniwersalne pojazdy transportowe przeznaczone do przewożenia
            wszelkiego rodzaju towarów. Dysponujemy solówkami w&nbsp;wielu
            wersjach, które dobieramy indywidualnie pod kątem specyfiki
            i&nbsp;potrzeb konkretnego zlecenia. Korzystamy wyłącznie
            z&nbsp;w&nbsp;pełni sprawnych, dobrze utrzymanych
            i&nbsp;funkcjonalnych ciężarówek, co pozwala nam na efektywne
            i&nbsp;bezpieczne wykonywanie różnego rodzaju przewozów.
          </>
        }
        image={<ImageSamochodyCiezarowe />}
        bgText="01"
      />
      <SectionImageText
        smallTitle="Flota"
        title={
          <>
            Ciągniki siodłowe <br />z naczepami
          </>
        }
        content={
          <>
            Tiry, jak potocznie określa się ciągniki siodłowe z&nbsp;naczepami,
            gwarantują ładowność do 24&nbsp;ton, firanki i&nbsp;plandeki –
            dodatkowo możliwość załadowania towarów z&nbsp;każdej strony –
            z&nbsp;boku, tyłu albo góry. W&nbsp;zależności od rodzaju
            przewożonych ładunków korzystamy z&nbsp;ciągników wyposażonych
            w&nbsp;naczepę o&nbsp;specjalnym przeznaczeniu, np. izotermę,
            chłodnię, wywrotkę, czy cysternę. Dzięki temu możemy sprawnie
            i&nbsp;zgodnie z&nbsp;obowiązującymi przepisami transportować
            materiały wszelkiego rodzaju, włącznie z&nbsp;sypkimi, płynnymi
            i&nbsp;niebezpiecznymi.
          </>
        }
        image={<ImageCiagniki />}
        bgText="02"
        reverseLarge
      />
      <SectionImageText
        smallTitle="Flota"
        title="Samochody ciężarowe&nbsp;+&nbsp;przyczepy (zestawy)"
        content={
          <>
            Ta kategoria pojazdów obejmuje opisane wyżej solówki wyposażone
            w&nbsp;przyczepę. Połączenie tego rodzaju znacząco zwiększa
            ładowność ciężarówki, umożliwiając transport dużej ilości towarów za
            jednym razem. Konieczność prowadzenia pojazdu z&nbsp;dodatkową
            przyczepą wymaga jednak od kierowcy dodatkowych uprawnień
            i&nbsp;odpowiedniego doświadczenia, dlatego współpracujemy wyłącznie
            ze sprawdzonymi partnerami, którzy działają zgodnie
            z&nbsp;przepisami i&nbsp;mają niezbędne kwalifikacje.
          </>
        }
        image={<ImageSamochodyCiezarowePrzyczepy />}
        bgText="03"
      />
      <SectionImageText
        smallTitle="Flota"
        title={
          <>
            Samochody <br />
            dostawcze (busy)
          </>
        }
        content={
          <>
            Mimo że ładowność busów jest relatywnie niewielka, to zapewniana
            przez nie możliwość swobodnego przewozu, bez konieczności stosowania
            się do restrykcyjnych regulacji prawnych dotyczących czasu pracy
            kierowcy, przekłada się na uniwersalność i&nbsp;sprawność ich
            użytkowania. Możliwość łatwego dotarcia tam, gdzie nie wjedzie
            ciężarówka, to kolejny aspekt przemawiający za wykorzystaniem busów
            do realizacji zleceń. Równie istotną kwestią jest atrakcyjny koszt
            przewozu.
          </>
        }
        image={<ImageSamochodyDostawcze />}
        bgText="04"
        reverseLarge
      />
      <SectionImageText
        smallTitle="Flota"
        title={
          <>
            Ciągniki siodłowe <br />z naczepami <br />
            specjalistycznymi
          </>
        }
        content={
          <>
            Dysponujemy naczepami przeznaczonymi do specjalistycznych zleceń,
            które umożliwiają bezpieczny i&nbsp;efektywny przewóz nietypowych
            towarów. Realizujemy dostawy z&nbsp;użyciem m.in. naczep odkrytych,
            czy tzw. niskopodwozia, które wykorzystuje się najczęściej przy
            transporcie ponadgabarytowym, do przewozu np. maszyn, pojazdów,
            części konstrukcyjnych. Dzięki temu możemy wykonywać nawet
            najbardziej skomplikowane i&nbsp;wymagające przewozy, których nie
            podejmują się inne firmy.
          </>
        }
        image={<ImageCiagnikiSpecjalistyczne />}
        bgText="05"
      />
      <SectionImageText
        smallTitle="Flota"
        title={
          <>
            Wywrotki, <br />
            cysterny, <br />
            silosy
          </>
        }
        content={
          <>
            Mamy dostęp do wszelkiego typu wywrotek, co pozwala na sprawne
            załadowywanie i&nbsp;przewożenie materiałów sypkich,
            w&nbsp;dowolnych ilościach oraz niezależnie od ich rodzaju. Również
            do ładunków sypkich, jednak głównie spożywczych, przeznaczone są
            silosy, których używamy w&nbsp;ramach współpracy z producentami
            rolnymi i&nbsp;firmami zajmującymi się handlem żywnością.
            Z&nbsp;kolei cysterny służą do transportu cieczy, gazów i&nbsp;ciał
            stałych, również tych niebezpiecznych.
          </>
        }
        image={<ImageWywrotki />}
        bgText="06"
        reverseLarge
      />
    </Layout>
  )
}
